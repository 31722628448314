export interface LexmeaLink {
  href: string;
  title: string;
}

export function useLexmeaLinks() {
  const { t } = useI18n();
  const infoItems = computed(
    () =>
      [
        {
          href: "/intro",
          title: t("intro"),
        },
        {
          href: "/vision",
          title: t("vision"),
        },
        {
          href: "/team",
          title: t("team"),
        },
        // {
        //   href: "/artikel",
        //   title: "Artikel",
        // },
        {
          href: "/faq",
          title: t("faq"),
        },
      ] as const satisfies LexmeaLink[]
  );

  const miscItems = computed(
    () =>
      [
        {
          href: "/agb",
          title: t("terms_and_conditions"),
        },
        {
          href: "/datenschutzhinweis",
          title: t("data_protection"),
        },
        {
          href: "/impressum",
          title: t("imprint"),
        },
        {
          href: "/schemata",
          title: t("schemata"),
        },
        {
          href: "/updates",
          title: t("updates"),
        },
      ] as const satisfies LexmeaLink[]
  );

  return {
    infoItems,
    miscItems,
  };
}
